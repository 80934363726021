/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import { createMessageWithSpecialTags, DHLButton, DHLButtonGroup, DHLModal, LocalizationPack } from "@gkuis/gkp-base-widgets";
import { observer } from "mobx-react-lite";
import { CustomerConfigStoreWithDelete } from "../../../types/CustomerConfigStoreWithDelete";
import { runInAction } from "mobx";

export type CustomerConfigDeleteButtonProps = {
  /** Ekp */
  ekp: string

  /** Localization Pack */
  localizationPack: LocalizationPack

  /** Customer Config Administration Store With Delete. */
  store: CustomerConfigStoreWithDelete;

  /** Disabled */
  disabled?: boolean;

  /** Disabled Tooltip*/
  disabledTooltip?: string;
}

export const CustomerConfigDeleteButton = observer(({
                                                      ekp,
                                                      localizationPack,
                                                      store,
                                                      disabled = false,
                                                      disabledTooltip
                                                    }: CustomerConfigDeleteButtonProps) => {

  const confirmDeleteActionbar = <>
    <DHLButton
        type="default"
        label={localizationPack.getButton("deleteConfigurationNo")}
        name="modalNo"
        onClick={() => store.closeConfigurationDeletion()}
        disabled={store.runningDelete}
    />
    <DHLButton
        type="primary"
        label={localizationPack.getButton("deleteConfigurationYes")}
        name="modalYes"
        onClick={() => store.confirmConfigurationDeletion(ekp)}
        disabled={disabled}
        loadingState={store.runningDelete}
        loadingTooltip={localizationPack.getTooltip("runningDelete")}
    />
  </>;

  const confirmDeleteDialog = <DHLModal
      name="confirm-delete-configuration"
      show={store.showConfigurationDeletionConfirm}
      title={localizationPack.getLabel("confirmDeleteConfiguration")}
      message={createMessageWithSpecialTags(localizationPack.getMsg("doYouWantToDeleteConfiguration"))}
      actionbar={confirmDeleteActionbar}
  />;

  return (
      <>
        {confirmDeleteDialog}
        <DHLButtonGroup name="buttons" alignment={"left"}>
          <DHLButton
              type="default"
              label={localizationPack.getButton("delete")}
              name="delete"
              onClick={() => runInAction(() => store.showConfigurationDeletionConfirm = true)}
              disabled={disabled}
              disabledTooltip={disabledTooltip}
          />
        </DHLButtonGroup>
      </>);
});
