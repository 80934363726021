/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import { observer } from "mobx-react-lite";
import { uid } from "react-uid";
import { CustomerConfigNumberRange } from "../../../types/CustomerConfig";

export type CustomerConfigColNumberRangeProps = {
  /** Name of cell. */
  name: string

  /** number ranges column. */
  value: CustomerConfigNumberRange[]

}

/** Output of the column number range. */
export const CustomerConfigColNumberRange = observer(({
                                                        name,
                                                        value,
                                                      }: CustomerConfigColNumberRangeProps) => {
  return (
      <>
        <div id={"col_" + name} data-testid={name + "_col"}>
          {value.map(numberRange => <div key={uid(numberRange.identifierNumComplete)}>{numberRange.identifierNumComplete}</div>)}
        </div>
      </>
  );
});
