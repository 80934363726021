/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import {
  createMessageWithSpecialTags,
  DHLTextInput,
  DHLTextOutput,
  DHLTooltip,
  FormField,
  LocalizationPack,
  ValidationRuleDataStore
} from "@gkuis/gkp-base-widgets";
import { action } from "mobx";
import { observer } from "mobx-react-lite";
import { useCallback, useEffect, useState } from "react";

export type CustomerConfigColRepresentativesProps = {
  name: string;
  representatives: string[];
  illegalRepresentatives: string[];
  disabled: boolean;
  disabledTooltip: boolean;
  updateCell: (newRepresentatives: string[], newRepresentativesInvalid: boolean) => void,
  localizationPack: LocalizationPack;
  validationRuleDataStore: ValidationRuleDataStore;
  inputFormFields: FormField<unknown>[];
}

const arraysEqual = (a: unknown[], b: unknown[]): boolean => a.length === b.length && a.every((value, index) => value === b[index]);

/** Output of the representative column. */
export const CustomerConfigColRepresentatives = observer(({
                                                            name,
                                                            representatives,
                                                            illegalRepresentatives,
                                                            disabled,
                                                            disabledTooltip,
                                                            updateCell,
                                                            localizationPack,
                                                            validationRuleDataStore,
                                                            inputFormFields
                                                          }: CustomerConfigColRepresentativesProps
) => {
  const [representativesFormField] = useState(() => {
    const repFormField = new FormField<string>(
        name,
        representatives.join(","),
        localizationPack,
        validationRuleDataStore,
        "",
        "representatives"
    );
    repFormField.validate();
    inputFormFields?.push(repFormField);
    return repFormField;
  });

  const [tooltipOpen, setTooltipOpen] = useState(false);

  useEffect(
      () => {
        if (!arraysEqual(representatives, representativesFormField.value.split(",").map(r => r.trim()))) {
          representativesFormField.updateValue(representatives.join(","));
          representativesFormField.clearError();
          representativesFormField.validate();
        }
      },
      [representativesFormField, representatives]
  );


  const onBlur = useCallback(
      action(() => {
        representativesFormField.clearError();
        representativesFormField.validate();
        const newRepresentatives = representativesFormField.value.split(",").map(r => r.trim());

        if (representativesFormField.value.trim().length > 0 && newRepresentatives.filter(r => illegalRepresentatives.includes(r)).length > 0) {
          representativesFormField.setError(localizationPack.getError("illegalRepresentativesInput"));
        }
        updateCell(newRepresentatives, representativesFormField.hasError);
      }),
      [representativesFormField, updateCell]
  );

  const tooltip =
      <DHLTextOutput textAlignment={"left"} value={createMessageWithSpecialTags(localizationPack.getTooltip("representativesDeactivated"))} />;

  return (
      <>
        <div id={"col_" + name} data-testid={name + "_col"}>
          <DHLTextInput
              formField={representativesFormField}
              disabled={disabled}
              onBlur={onBlur}
          />
        </div>
        {disabledTooltip &&
        <div>
          <DHLTooltip
              testid={"col_" + name + "-tooltip"}
              placement={"auto-end"}
              tooltipOpen={tooltipOpen}
              target={"col_" + name}
              toggle={useCallback(() => setTooltipOpen(oldTooltipOpen => !oldTooltipOpen), [])}
          >
            {tooltip}
          </DHLTooltip></div>}
      </>
  );
});
