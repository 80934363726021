/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import { DHLTableCellBoolean, LocalizationPack } from "@gkuis/gkp-base-widgets";

/**
 * groups the boolean-flag-columns for contractBillingVariations (german name "Abrechnungsvarianten")
 */
export const CustomerConfigColsBillingVariations = ((props: { localizations: LocalizationPack }) => {
  if (!props.localizations.hasData) {
    return [];
  }

  return ([
    <DHLTableCellBoolean
        propertiesName="goGreen"
        key="goGreen"
        disabledPropertiesName="invalid"
        title={props.localizations.getLabel("colGoGreen")}
        disableMinWidth={true}
        sortable={true}
    />,
    <DHLTableCellBoolean
        propertiesName="mixedCalculation"
        key="mixedCalculation"
        disabledPropertiesName="invalid"
        title={props.localizations.getLabel("colMixedCalculation")}
        disableMinWidth={true}
        sortable={true}
    />,
    <DHLTableCellBoolean
        propertiesName="mandatorCalculation"
        key="mandatorCalculation"
        disabledPropertiesName="invalid"
        title={props.localizations.getLabel("colMandatorCalculation")}
        disableMinWidth={true}
        sortable={true}
    />
  ]);
});

  