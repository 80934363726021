/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import { createMessageWithSpecialTags, DHLTextOutput, DHLTooltip, formatDate, LocalizationPack } from "@gkuis/gkp-base-widgets";
import { observer } from "mobx-react-lite";
import  { useCallback, useState } from "react";

export type CustomerConfigColContractBillingNumberProps = {
  /** Name der Zelle. */
  name: string;

  /** Zugriff auf Lokalisierungen. */
  localizations: LocalizationPack;

  /** Die gesamte Zeile. */
  row: {
    contractBillingNumber: string,
    bookingText: string | null,
    validFrom: string,
    validUntil: string,
  };

}

/** Ausgabe der Spalte Abrechnungsnummer. */
export const CustomerConfigColContractBillingNumber = observer(({
                                                                  name,
                                                                  localizations,
                                                                  row
                                                                }: CustomerConfigColContractBillingNumberProps) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const tooltipText = localizations.getTooltip("contractBillingNumber") + " " + row.contractBillingNumber
      + "[br]" +
      localizations.getTooltip("contractValidTo") + " " + formatDate(row.validFrom, "L") + " " +
      localizations.getTooltip("contractValidUntil") + " " + formatDate(row.validUntil, "L");
  const tooltip =
      <DHLTextOutput textAlignment={"left"} value={createMessageWithSpecialTags(tooltipText)} />;

  return (
      <>
        <div id={"col_" + name} data-testid={name + "_col"}>
          <div>{row.contractBillingNumber}</div>
          <div>{row.bookingText}</div>
        </div>
        <DHLTooltip
            testid={"col_" + name + "-tooltip"}
            noMaxWidth={true}
            placement={"right"}
            tooltipOpen={tooltipOpen}
            target={"col_" + name}
            toggle={useCallback(() => setTooltipOpen(oldTooltipOpen => !oldTooltipOpen), [])}
        >
          {tooltip}
        </DHLTooltip>
      </>
  );
});
