/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import { createMessageWithSpecialTags, DHLCheckbox, DHLTextOutput, LocalizationPack } from "@gkuis/gkp-base-widgets";
import { observer } from "mobx-react-lite";

import { Invalidateable } from "../../../types/CustomerConfig";

export type CustomerConfigColSelectRow = { numberRanges?: Invalidateable[], customInvalidReasonTooltip?: string } & Invalidateable

export type CustomerConfigColSelectProps = {
  /** Cell name. */
  name: string;

  /** Access to localizations. */
  localizations: LocalizationPack;

  /** column value. */
  value: boolean;

  /** complete row. */
  row: CustomerConfigColSelectRow;

  disabled?: boolean;

  /** function for onChange-calls. */
  onChange: (newValue: boolean) => void;

}

function getInvalidReasonsTooltipForRow(row: CustomerConfigColSelectRow, localizations: LocalizationPack) {
  // returned string must be safe of user controlled text
  if (row.invalid) {
    const localizedValuesForContract = getInvalidReasonsTooltipForObject(row, localizations);
    const localizedValuesForNumberRanges = row.numberRanges
        ?.flatMap(numberRange => getInvalidReasonsTooltipForObject(numberRange, localizations))
        ?? [];
    const localizedValues = localizedValuesForContract.concat(localizedValuesForNumberRanges);
    if (localizedValues.length > 0) {
      return localizations.getTooltip("invalidTitle") + "[br]" + localizedValues.join("[br]");
    }
  }
  if (row.customInvalidReasonTooltip) {
    return localizations.getTooltip(row.customInvalidReasonTooltip)
  }
  return "";
}

function getInvalidReasonsTooltipForObject(object: Invalidateable, localizations: LocalizationPack) {
  if (object.invalidReasons !== undefined && object.invalidReasons.length > 0) {
    return object.invalidReasons.map(reason =>
        localizations.getTooltip(`invalidReason.${reason.type}`, reason.localizationParams.slice())
    );
  }
  return [];
}

/** output of column billing number. */
export const CustomerConfigColSelect = observer(({
                                                   name,
                                                   localizations,
                                                   value,
                                                   row,
                                                   disabled = false,
                                                   onChange
                                                 }: CustomerConfigColSelectProps) => {
  return (
      <div data-testid={name + "_col"}>
        <DHLCheckbox name={name + "_colSel"}
                     value={value}
                     disabled={disabled || row.invalid || row.customInvalidReasonTooltip !== undefined}
                     onChange={() => onChange(!value)}
                     disabledTooltip={
                       row.invalid ?
                           <DHLTextOutput textAlignment={"left"}
                                          value={createMessageWithSpecialTags(getInvalidReasonsTooltipForRow(row, localizations))} />
                           : row.customInvalidReasonTooltip ?
                               <DHLTextOutput textAlignment={"left"}
                                              value={createMessageWithSpecialTags(getInvalidReasonsTooltipForRow(row, localizations))} />
                           : undefined
                       }
                     className={"position-static"}
        />
      </div>
  );
});
