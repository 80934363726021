/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import { useEffect } from "react";
import { LocalizationPack } from "@gkuis/gkp-base-widgets";
import { CustomerConfigStore } from "../types/CustomerConfigStore";

export const useLoadCustomerConfiguration = (
    ekp: string,
    store: CustomerConfigStore,
    localizationPack: LocalizationPack
) => {

  useEffect(() => {
    if (localizationPack.hasData) {
      store.clearTemporaryMessages();
      store.loadConfiguration(ekp);
    }
  }, [ekp, localizationPack.hasData, store]);
};