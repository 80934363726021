/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import {
  DHLRadioButton
} from "@gkuis/gkp-base-widgets";
import { observer } from "mobx-react-lite";
import { uid } from "react-uid";
import { CustomerConfigNumberRange } from "../../../types/CustomerConfig";
import classNames from "classnames";

export type CustomerConfigColNumberRangeWithRadioButtonProps = {
  /** Name of cell. */
  name: string

  /** number ranges column. */
  numberRanges: CustomerConfigNumberRange[]

  selectedNumberRangeIdentifierNumComplete?: string

  onChange: (newValue: string) => void;

  disabled?: boolean
}

/** Output of the column number range. */
export const CustomerConfigColNumberRangeWithRadioButton = observer((
    {
      name,
      numberRanges,
      selectedNumberRangeIdentifierNumComplete,
      onChange,
      disabled = false
    }: CustomerConfigColNumberRangeWithRadioButtonProps
) => {
  return (
      <div className={classNames("customerConfigColNumberRangeWithRadioButton")}>
        <div id={`col_${name}`}>
          {numberRanges.map(numberRange =>
              <DHLRadioButton
                  name={`${name}_${numberRange.identifierNumComplete}_sel`}
                  selectValue={numberRange.identifierNumComplete}
                  value={selectedNumberRangeIdentifierNumComplete}
                  label={numberRange.identifierNumComplete}
                  disabled={disabled}
                  onChange={onChange}
                  key={uid(numberRange.identifierNumComplete)}
              />
          )}
        </div>
      </div>
  );
});
