/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import { DHLTableCellAllSelectableAccessors, DHLTableCellCustom, LocalizationPack } from "@gkuis/gkp-base-widgets";

import { CustomerConfigContract, CustomerConfigNumberRange, Invalidateable } from "../../../types/CustomerConfig";
import { CustomerConfigColContractBillingNumber } from "../CustomerConfigColContractBillingNumber/CustomerConfigColContractBillingNumber";
import { CustomerConfigColNumberRange } from "../CustomerConfigColNumberRange/CustomerConfigColNumberRange";
import { CustomerConfigColSelect, CustomerConfigColSelectRow } from "../CustomerConfigColSelect/CustomerConfigColSelect";

/**
 * Groups the columns for selected, contractBillingNumber and numberRanges.
 */
export const CustomerConfigCommonLeadingCols = (
    localizations: LocalizationPack,
    onSelectionChange: (row: Object, cellId: string) => (newValue: boolean) => void,
    numberRangeFilter: (numberRange: CustomerConfigNumberRange) => boolean = () => true,
    readOnlyMode: boolean = false
) => {
  if (!localizations.hasData) {
    return [];
  }

  const renderSelect = (value: boolean, row: Object, cellId: string) =>
      <CustomerConfigColSelect
          name={cellId}
          localizations={localizations}
          value={value}
          row={row as CustomerConfigColSelectRow}
          onChange={onSelectionChange(row, cellId)}
          disabled={readOnlyMode}
      />;

  const renderContractBillingNumber = (value: string, row: Object, cellId: string) =>
      <CustomerConfigColContractBillingNumber
          name={cellId}
          localizations={localizations}
          row={row as CustomerConfigContract}
      />;

  const renderNumberRange = (values: CustomerConfigNumberRange[], row: Object, cellId: string) =>
      <CustomerConfigColNumberRange
          name={cellId}
          value={values.filter(numberRangeFilter)}
      />;

  interface AllSelectableRow extends Invalidateable {
    selected: boolean;
    customInvalidReasonTooltip?: string;
  }

  const allSelectableAccessors: DHLTableCellAllSelectableAccessors<AllSelectableRow> = {
    isSelected: row => row.selected,
    isSelectable: row => row.customInvalidReasonTooltip === "" ? false : (!row.invalid && !row.customInvalidReasonTooltip),
    setSelectedState: (row, newState) => row.selected = newState
  };

  return ([
    <DHLTableCellCustom
        key="selected"
        propertiesName="selected"
        customRenderer={renderSelect}
        title={""}
        sortable={false}
        allSelectableAccessors={allSelectableAccessors}
        showConfirmationDialogs={["deselection"]}
        disableMinWidth={true}
    />,
    <DHLTableCellCustom
        key="contractBillingNumber"
        propertiesName="contractBillingNumber"
        customRenderer={renderContractBillingNumber}
        title={localizations.getLabel("colContractBillingNumber")}
        sortable={true}
    />,
    <DHLTableCellCustom
        key="numberRanges"
        propertiesName="numberRanges"
        customRenderer={renderNumberRange}
        title={localizations.getLabel("colNumberRange")}
        sortable={true}
    />
  ]);
};

  