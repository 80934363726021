/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import {
  createMessageWithSpecialTags,
  DHLRadioButton,
  DHLTextOutput,
  DHLTooltip,
  formatDate,
  LocalizationPack
} from "@gkuis/gkp-base-widgets";
import { observer } from "mobx-react-lite";
import  { useCallback, useState } from "react";
import { uid } from "react-uid";
import { CustomerConfigCovenant } from "../../../types/CustomerConfig";
import moment from "moment";

export type CustomerConfigColCovenantProps = {
  /** Name of cell. */
  name: string

  /** access to localizations. */
  localizations: LocalizationPack

  /** covenants to select or given covenant in column. */
  value: CustomerConfigCovenant[] | CustomerConfigCovenant

  /** selected covenant id */
  selectedCovenantId?: string

  /** on change selection or null if selection not allowed.. */
  onChange?: (newValue: string) => void

  /** disable radio buttons. */
  disabled?: boolean

}

export const isValid = (covenant: CustomerConfigCovenant) => {
  if (covenant.validUntil === "") {
    return true;
  }
  const validUntilDate = moment(covenant.validUntil);
  const today = moment(new Date(), "L", true);
  return today.isBefore(validUntilDate);
};

/** Output of the column number range. */
export const CustomerConfigColCovenant = observer(({
                                                     name,
                                                     localizations,
                                                     value,
                                                     selectedCovenantId,
                                                     onChange,
                                                     disabled
                                                   }: CustomerConfigColCovenantProps
) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const formatValue = useCallback(
      (singleValue: CustomerConfigCovenant) =>
          `${localizations.getTooltip("covenantId")} ${singleValue.covenantId}[br]` +
          `${localizations.getTooltip("covenantType")} ${singleValue.covenantType}[br]` +
          `${localizations.getTooltip("covenantStatus")} ${singleValue.covenantStatus}[br]` +
          `${localizations.getTooltip("covenantValidTo")} ${formatDate(singleValue.validFrom, "L")} ` +
          `${localizations.getTooltip("covenantValidUntil")} ${formatDate(singleValue.validUntil, "L")}`,
      [localizations]);

  const tooltipText = value instanceof Array
      ? value.map(formatValue).join("[br][br]")
      : formatValue(value);

  return (
      <>
        <div id={"col_" + name} data-testid={name + "_col"}>
          {value instanceof Array ? value.map(covenant =>
                  <DHLRadioButton
                      name={`${name}_${covenant.covenantId}_sel`}
                      selectValue={covenant.covenantId}
                      value={selectedCovenantId}
                      label={covenant.covenantId}
                      disabled={disabled || !isValid(covenant)}
                      onChange={onChange}
                      key={uid(covenant.covenantId)}
                  />
              )
              : value.covenantId}
        </div>
        <DHLTooltip
            testid={"col_" + name + "-tooltip"}
            noMaxWidth={true}
            placement={"right"}
            tooltipOpen={tooltipOpen}
            target={"col_" + name}
            toggle={useCallback(() => setTooltipOpen(oldTooltipOpen => !oldTooltipOpen), [])}
        >
          <DHLTextOutput textAlignment={"left"} value={createMessageWithSpecialTags(tooltipText)} />
        </DHLTooltip>
      </>
  );
});
